import { createGlobalStyle } from 'styled-components';

const Variables = createGlobalStyle`

  :root {
    --text-color: #fff;
    --bg-color: #000;

    /* Spacing */
    --spacing-XXXS: 0.5rem;
    --spacing-XXS: 1.5rem;
    --spacing-XS: 2.5rem;
    --spacing-S: 4rem;
    --spacing-M: 6rem;
    --spacing-L: 8rem;
    --spacing-XL: 13rem;
    --spacing-XXL: 21rem;

    /* Variable Spacing */
    /* Clamp Fallback */ --v-spacing-S: var(--spacing-XS);
    --v-spacing-S: clamp(var(--spacing-XXS), 3vw, var(--spacing-S));
    /* Clamp Fallback */ --v-spacing-M: 4rem;
    --v-spacing-M: clamp(var(--spacing-M), 2vw, var(--spacing-L));
    /* Clamp Fallback */ --v-spacing-L: 10rem;
    --v-spacing-L: clamp(var(--spacing-L), 5vw, var(--spacing-XL));
    /* Clamp Fallback */ --v-spacing-XL: 17rem;
    --v-spacing-XL: clamp(var(--spacing-XL), 5vw, var(--spacing-XXL));

    /* Fonts */
    --font-1: 'Favorit', sans-serif;
    --font-2: 'Jannon', serif;
    --font-3: 'JannonItalic', serif;
    /* Clamp Fallback */ --headline: calc(35px + (60 - 35) * (100vw - 375px) / (2560 - 375));
    --headline: clamp(3.5rem, 4vw, 6rem);
    /* Clamp Fallback */ --copy: calc(20px + (30 - 20) * (100vw - 375px) / (2560 - 375));
    --copy: clamp(2rem, 3vw, 3rem);
    --copy-small: 1.3rem;
    --letter-spacing: -0.9px;
    --line-height: 1.15;

    /* Border */
    --border-width: 4px;
    --border: var(--border-width) solid var(--text-color);
    --border-small: 1px solid var(--text-color);

    /* Layout */
    --grid-cols: 24;
    --grid-gap: var(--spacing-XS);

    /* Misc */
  }
`;

export default Variables;
