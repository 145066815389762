import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const MenuBtn = ({ onClick, triggerAnim, hidden }) => {
  const duration = 0.15;
  const space = 5;

  const firstLine = {
    closed: {
      x: -space,
      transition: { duration },
    },
    open: {
      x: 0,
      transition: { duration },
    },
  };

  const thirdLine = {
    closed: {
      x: space,
      transition: { duration },
    },
    open: {
      x: 0,
      transition: { duration },
    },
  };

  return (
    <StyledMenuBtn
      onClick={onClick}
      className="menu-btn"
      style={{ display: !hidden ? 'flex' : 'none' }}
    >
      <motion.span
        className="line"
        variants={firstLine}
        initial="closed"
        animate={triggerAnim ? 'open' : 'closed'}
      />
      <motion.span className="line center" />
      <motion.span
        className="line"
        variants={thirdLine}
        initial="closed"
        animate={triggerAnim ? 'open' : 'closed'}
      />
    </StyledMenuBtn>
  );
};

const StyledMenuBtn = styled.button`
  z-index: 2;
  height: 50px;
  width: 50px;
  display: flex;
  position: fixed;
  right: calc(var(--v-spacing-S) + 5px);
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  mix-blend-mode: difference;

  &:focus {
    outline: 0px;
  }

  .line {
    width: 5px;
    height: 5px;
    background: #fff;
  }
`;

MenuBtn.propTypes = {
  onClick: PropTypes.func,
  triggerAnim: PropTypes.bool,
  hidden: PropTypes.bool,
};

export default MenuBtn;
