import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import GlobalStyles from '../../styles/globalStyles';
import Normalize from '../../styles/normalize';
import Variables from '../../styles/variables';
import Footer from '../molecules/footer';
import LayoutProvider from './layoutProvider';
import { ease } from '../../utils/easing';
import Nav from '../molecules/nav';
import { useDetectBrowser } from '../../hooks/useDetectBrowser';
import { useWindowSize } from '../../hooks/useWindowSize';

const Layout = ({ children, location }) => {
  const size = useWindowSize();
  const isMobile = size.width < 768;
  const { isSafari } = useDetectBrowser();
  return (
    <LayoutProvider>
      {/**
       * Styles
       */}
      <Normalize />
      <GlobalStyles />
      <Variables />
      {/**
       * Content
       */}
      <Nav />
      <AnimatePresence exitBeforeEnter initial={false}>
        <motion.main
          key={location && `key${location.pathname}`}
          initial={{
            filter: isSafari && !isMobile ? 'blur(0px)' : 'blur(10px)',
            opacity: isSafari && !isMobile ? 0 : 1,
          }}
          animate={{
            filter: isSafari && !isMobile ? 'blur(0px)' : 'blur(0px)',
            opacity: isSafari && !isMobile ? 1 : 1,
          }}
          exit={{
            filter: isSafari && !isMobile ? 'blur(0px)' : 'blur(10px)',
            opacity: isSafari && !isMobile ? 0 : 1,
          }}
          transition={{ duration: 0.4, ease: ease.inOutSmooth }}
        >
          {children}
        </motion.main>
      </AnimatePresence>
      <Footer />
    </LayoutProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
};

export default Layout;
