import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useWindowSize } from '../../hooks/useWindowSize';
import MenuBtn from '../atoms/menuBtn';
import CollapsedNav from '../atoms/collapsedNav';
import { media } from '../../utils/media-queries';
import RadioButton from '../atoms/radioButton';
import { LayoutContext } from '../organisms/layoutProvider';

const Nav = () => {
  const { contact, blog, project, about } = useStaticQuery(graphql`
    query Nav {
      contact: allSanityContact {
        nodes {
          title {
            de
            en
          }
          facebook
          instagram
          shop
        }
      }
      blog: allSanityBlogPage {
        nodes {
          title {
            de
            en
          }
        }
      }
      project: allSanityProjectsPage {
        nodes {
          title {
            de
            en
          }
        }
      }
      about: allSanityAbout {
        nodes {
          title {
            de
            en
          }
        }
      }
    }
  `);

  const size = useWindowSize();
  const isMobile = size.width < 768;

  const [showNav, setShowNav] = useState(false);
  const [hideBtn, setHideBtn] = useState(true);

  const ref = useRef();

  useEffect(() => {
    if (isMobile && showNav) {
      disableBodyScroll(ref.current);
    } else if (!(isMobile && showNav)) {
      clearAllBodyScrollLocks();
    }
  }, [isMobile, showNav]);

  useEffect(() => {
    if (isMobile) {
      setShowNav(false);
      setHideBtn(false);
    } else {
      setShowNav(true);
      setHideBtn(true);
    }
  }, [isMobile]);

  const { language, setLanguage } = useContext(LayoutContext);

  const [year, setYear] = useState();
  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);

  return (
    <StyledHeader ref={ref}>
      {isMobile && <MenuBtn onClick={() => setShowNav(!showNav)} triggerAnim={showNav} hidden={hideBtn} />}
      <CollapsedNav active={showNav} initialClosed={isMobile} onClick={() => isMobile && setShowNav(!showNav)}>
        <li className="nav-item">
          <ul initial="hidden" animate="show">
            <li className="hover">
              <Link to={language === 'en' ? '/' : '/de/'}>Home</Link>
            </li>
            <li className="desktop" />
            <li className="hover">
              <Link to={language === 'en' ? '/blog/' : '/de/blog/'}>{blog.nodes[0].title[language] || blog.nodes[0].title.en}</Link>
            </li>
            <li className="hover">
              <Link to={language === 'en' ? '/projects/' : '/de/projects/'}>{project.nodes[0].title[language] || project.nodes[0].title.en}</Link>
            </li>
            <li className="mobile hover">
              <Link to={language === 'en' ? '/about/' : '/de/about/'}>{about.nodes[0].title[language] || about.nodes[0].title.en}</Link>
            </li>
            <li className="hover">
              <Link to={language === 'en' ? '/contact/' : '/de/contact/'}>{contact.nodes[0].title[language] || contact.nodes[0].title.en}</Link>
            </li>
            <li className="desktop copyright">Claudia</li>
            <br className="mobile" />
            <br className="mobile" />
            <li className="mobile hover">
              <RadioButton
                value="de"
                onClick={() => setShowNav(false)}
                onChange={(e) => setLanguage(e.target.value)}
                label="DE"
                checked={language === 'de'}
                style={{ fontFamily: language === 'de' && 'var(--font-3)' }}
              />
            </li>
          </ul>
        </li>
        <li className="nav-item desktop">
          <ul>
            <li className="hover">
              <RadioButton
                value="de"
                onChange={(e) => setLanguage(e.target.value)}
                label="DE"
                checked={language === 'de'}
                style={{ fontFamily: language === 'de' && 'var(--font-3)' }}
              />
            </li>
            <li className="hover">
              <RadioButton
                value="en"
                onChange={(e) => setLanguage(e.target.value)}
                label="EN"
                checked={language === 'en'}
                style={{ fontFamily: language === 'en' && 'var(--font-3)' }}
              />
            </li>
            <li className="hover">
              <Link to={language === 'en' ? '/about/' : '/de/about/'}>{about.nodes[0].title[language] || about.nodes[0].title.en}</Link>
            </li>
            <li className="desktop" />
            <li className="desktop" />
            <li className="desktop copyright">Six</li>
            <li />
          </ul>
        </li>
        <li className="nav-item">
          <ul>
            <li className="hover">
              <a href={contact.nodes[0].instagram} target="_blank" rel="noopener noreferrer">
                Insta
              </a>
            </li>
            <li className="hover">
              <a href={contact.nodes[0].facebook} target="_blank" rel="noopener noreferrer">
                Faceb
              </a>
            </li>
            <li className="desktop" />
            <br className="mobile" />
            {/* <li className="hover">
              <a href={contact.nodes[0].shop} target="_blank" rel="noopener noreferrer">
                Shop
              </a>
            </li> */}
            <li className="hover">
              <a href="https://claudiasix.substack.com/" target="_blank" rel="noopener noreferrer">
                Newsletter
              </a>
            </li>
            <li className="desktop" />
            <li className="desktop copyright">
              <span className="desktop copyright-sign">©</span>
              {year}
            </li>
            <br className="mobile" />
            <br className="mobile" />
            <li className="mobile hover">
              <RadioButton
                value="en"
                onClick={() => setShowNav(false)}
                onChange={(e) => setLanguage(e.target.value)}
                label="EN"
                checked={language === 'en'}
                style={{ fontFamily: language === 'en' && 'var(--font-3)' }}
              />
            </li>
            <br className="mobile" />
            <span className="sign mobile">©</span>
            <li className="copyright mobile">
              Claudia <br /> Six <br /> {year}
            </li>
          </ul>
        </li>
      </CollapsedNav>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  .nav {
    background: var(--bg-color);
    color: var(--text-color);
    width: 100vw;
    position: fixed;
    bottom: 0;
    z-index: 1;
    height: 100vh;

    .wrapper {
      padding-top: calc(var(--spacing-L) * 1.5);
      display: flex;

      .nav-item {
        width: 50%;
      }

      ul {
        text-transform: uppercase;
        line-height: 2;
      }
    }
  }

  .desktop {
    display: none;
  }

  .copyright {
    font-family: var(--font-1);
    line-height: var(--line-height);
    font-size: var(--copy-small);
  }

  .copyright {
    position: relative;
  }

  .sign {
    position: absolute;
    font-family: var(--font-2);
    font-size: var(--headline);
    transform: translateX(-37px);
  }

  @media ${media.M} {
    .mobile {
      display: none;
    }

    .desktop {
      display: block;
    }

    .nav {
      border-top: var(--border);
      border-bottom: var(--border);
      display: flex;
      height: 13vh;

      .wrapper {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 0;

        .nav-item {
          width: 40%;
        }

        ul {
          display: flex;
          font-family: var(--font-1);
          font-size: var(--copy-small);
          line-height: var(--line-height);

          li {
            min-width: 23%;
          }

          .copyright {
            padding-left: 45%;
            position: relative;
          }

          .copyright-sign {
            position: absolute;
            font-size: calc(var(--copy-small) * 2);
            font-family: var(--font-2);
            transform: translate3d(-27px, -15px, 0);
          }

          li,
          label {
            cursor: pointer;
          }
        }
      }
    }
  }

  @media (max-width: 350px) {
    .copyright,
    .sign {
      display: none;
    }
  }
`;

export default Nav;
