import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { ease } from '../../utils/easing';
import ThemeSwitch from '../../assets/svgs/themeswitch.svg';
import { LayoutContext } from '../organisms/layoutProvider';
import { useWindowSize } from '../../hooks/useWindowSize';
import { media } from '../../utils/media-queries';

const textWrapper = {
  open: {
    opacity: 1,
    transition: { ease: ease.smoothIn, duration: 0.2 },
  },
  close: {
    opacity: 0,
    transition: { ease: ease.smoothIn, duration: 0.2 },
  },
};

const text = {
  hidden: { opacity: 0, transition: { ease: ease.smoothOut, duration: 0.2 } },
  show: {
    opacity: 1,
    transition: {
      ease: ease.smoothOut,
      duration: 0.5,
      delay: 0.2,
    },
  },
};

const CollapsedNav = ({ active, initialClosed = true, children, onClick }) => {
  const size = useWindowSize();
  const isMobile = size.width < 768;

  const [showSwitcher, setShowSwitcher] = useState(false);
  const { theme, toggleTheme } = useContext(LayoutContext);

  useEffect(() => {
    if (isMobile) setShowSwitcher(true);

    if (theme === 'light') {
      document.documentElement.style.setProperty('--text-color', '#000');
      document.documentElement.style.setProperty('--bg-color', '#fff');
      document.documentElement.style.setProperty('--font-1', 'Jannon');
      document.documentElement.style.setProperty('--font-2', 'Favorit');
    }
    if (theme === 'dark') {
      document.documentElement.style.setProperty('--text-color', '#fff');
      document.documentElement.style.setProperty('--bg-color', '#000');
      document.documentElement.style.setProperty('--font-1', 'Favorit');
      document.documentElement.style.setProperty('--font-2', 'Jannon');
    }
  }, [theme, isMobile]);

  return (
    <AnimatePresence>
      {active && (
        <StyledNav
          className="nav spacing-inner"
          style={{ overflow: 'hidden' }}
          variants={textWrapper}
          initial={initialClosed ? 'close' : 'open'}
          animate={active ? 'open' : 'close'}
          exit={initialClosed ? 'close' : 'open'}
          onMouseEnter={() => !isMobile && setShowSwitcher(true)}
          onMouseLeave={() => !isMobile && setShowSwitcher(false)}
          onClick={onClick}
        >
          <motion.div
            key="switcher"
            className="switcher"
            variants={text}
            initial={initialClosed || !showSwitcher ? 'hidden' : 'show'}
            animate={active && showSwitcher ? 'show' : 'hidden'}
            exit={initialClosed || !showSwitcher ? 'hidden' : 'show'}
            onClick={toggleTheme}
          >
            <ThemeSwitch />
          </motion.div>
          <motion.ul
            key="text"
            className="wrapper"
            variants={text}
            initial={initialClosed ? 'hidden' : 'show'}
            animate={active ? 'show' : 'hidden'}
            exit={initialClosed ? 'hidden' : 'show'}
          >
            {children}
          </motion.ul>
        </StyledNav>
      )}
    </AnimatePresence>
  );
};

const StyledNav = styled(motion.nav)`
  .switcher {
    position: absolute;
    width: 100%;
    display: flex;
    bottom: var(--v-spacing-S);
    padding-right: var(--v-spacing-S);
    width: 100%;

    svg {
      width: 75%;
    }

    path {
      fill: var(--text-color);
      stroke: var(--text-color);
    }
  }

  @media ${media.M} {
    .switcher {
      border-right: var(--border);
      width: 25%;
      height: 100%;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      top: 0;

      svg {
        width: 100%;
      }
    }
  }
`;

CollapsedNav.propTypes = {
  active: PropTypes.bool,
  initialClosed: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default CollapsedNav;
