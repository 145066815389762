import { createGlobalStyle } from 'styled-components';
import Favorit from '../assets/fonts/ABCFavorit-Regular.woff2';
import Jannon from '../assets/fonts/JannonText.woff';
import JannonItalic from '../assets/fonts/JannonText-Italic.woff';
import { media } from '../utils/media-queries';

const GlobalStyles = createGlobalStyle`

  html {
    font-family: var(--font-2);
    color: var(--text-color);
    background: var(--bg-color);
    font-size: 10px;
  }

  body {
    font-size: var(--copy);
    line-height: var(--line-height);
  }

  main {
    overflow-x: hidden;
  }

  /* Typography */

  @font-face {
    font-family: Favorit;
    src: url(${Favorit});
  }

  @font-face {
    font-family: Jannon;
    src: url(${Jannon});
  }

  @font-face {
    font-family: JannonItalic;
    src: url(${JannonItalic});
  }

  .headline {
    font-family: var(--font-1);
    font-size: var(--headline);
    letter-spacing: -1.75px;
    line-height: 1.4;
  }
  
  .copy-small {
    font-size: var(--copy-small);
    line-height: 1.2;
  }

  /* Images */

  .gatsby-image-wrapper {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
  }


  .video {
    position: relative;
    padding-top: 56.25%;
    height: 0;
    overflow: hidden;
    width: 100%;
    height: auto;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  /* Scrollbar */

  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  /* Layout */

  .spacing-outer {
    margin: var(--v-spacing-S);
  }

  .spacing-inner {
    padding: var(--v-spacing-S);
  }

  .spacing-removed {
    margin-left: calc(var(--v-spacing-S) * -1);
    margin-bottom: calc(var(--v-spacing-S) * -1);
  }

  .hover {
    transition: all 0.5s ease-in;

    &:hover {
      filter: blur(6px);
    }
  }

  p {
    a {
      text-decoration: underline;
    }
  }

  @media ${media.M} {
    body {
      padding-bottom: 12.5vh;
    }
  }

`;

export default GlobalStyles;
