import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useActiveLang } from '../../hooks/useActiveLang';

export const LayoutContext = createContext();

const LayoutProvider = ({ children }) => {
  const activeLang = useActiveLang();
  const [language, setLanguage] = useState(activeLang);
  const [theme, setTheme] = useState('dark');
  const toggleTheme = () => setTheme(theme === 'light' ? 'dark' : 'light');

  return (
    <LayoutContext.Provider
      value={{ theme, toggleTheme, setTheme, language, setLanguage }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

LayoutProvider.propTypes = {
  children: PropTypes.node,
};

export default LayoutProvider;
